import Lottie from 'react-lottie';
import animationData from './51936-sad-tear.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function LoseLottie() {
  return (
    <div style={{ top: '35%', left: '26%', position: 'fixed', pointerEvents: 'none' }}>

      <Lottie
        options={defaultOptions}
        height={250}
        width={250}
        isStopped={false}
      />

    </div>
  );
}

export default LoseLottie;
