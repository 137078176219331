// import './css/style.css';

function Welcome({callNextScreen}) {
  return (
    <div class="container">

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
        <div class="text-bg">
          <h1>Welcome to the <br /> <strong class="b_bold">Baum's Casino </strong></h1>
          <span>
            Sapir, everyone knows how much you love the Casino,<br />
            so you arrived to the best Casino in town! <br />
            Let's get ready to Gumbleeeeeee!!! <br />
          </span>
          <a onClick={callNextScreen}>Casino Rules</a>
          {/* <a href="#">Book Online</a> */}
        </div>
      </div>
      {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12"> */}
      {/* <div class="text-img"> */}
      {/* </div>
          </div> */}
    </div>

  </div>
  );
}

export default Welcome;
