import React, { useState, useEffect, useMemo } from 'react'
import { Wheel } from 'react-custom-roulette'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import './style.css'
import WinLottie from '../../lottie/WinLottie'

// const data = [
//   { option: '0' },
//   { option: '1' },
//   { option: '2' },
// ]
// const data = [
//   { option: '0', style: { backgroundColor: 'green', textColor: 'black' } },
//   { option: '1', style: { backgroundColor: 'white' } },
//   { option: '2' },
// ]
const data = [
  { option: '0', style: { backgroundColor: '#006900' } },
  { option: '1' },
  { option: '2' },
  { option: '3' },
  { option: '4' },
  { option: '5' },
  { option: '6' },
  { option: '7' },
  { option: '8' },
  { option: '9' },
  { option: '10' },
  { option: '11' },
  { option: '12' },
  { option: '13' },
  { option: '14' },
  { option: '15' },
  { option: '16' },
  { option: '17' },
  { option: '18' },
  { option: '19' },
  { option: '20' },
  { option: '21' },
  { option: '22' },
  { option: '23' },
  { option: '24' },
  { option: '25' },
  { option: '26' },
  // { option: '27' },
  // { option: '28' },
  // { option: '29' },
  // { option: '30' },
];

const backgroundColors = ['#3e3e3e', '#df3428'];
const textColors = ['white'];
const outerBorderColor = '#d8a35a';
const outerBorderWidth = 8;
const innerBorderColor = '#d8a35a';
const innerBorderWidth = 17;
const innerRadius = 40;
const radiusLineColor = '#dddddd';
const radiusLineWidth = 3;
const fontSize = 20;
const textDistance = 86;

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function Roulette({ callNextScreen, credit, updateCredit }) {
  const classes = useStyles();

  const [showLottie, setShowLottie] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [rounds, setRounds] = useState(0);
  const [colorLoseRounds, setColorLoseRounds] = useState(1);
  const [numberLoseRounds, setNumberLoseRounds] = useState(1);
  const [currentBet, setCurrentBet] = useState('');
  const [betColor, setBetColor] = useState('');
  const [betNumber, setBetNumber] = useState(''); //Actually is number
  const [endGame, setEndGame] = useState(0);



  const getPrizeNumber = () => {
    // return 17; //test
    const randomIndex = Math.floor(Math.random() * data.length);
    // if (!rounds) return randomIndex;
    if (currentBet==='color') {
      if (rounds % 10 === 0) return 0;
      if (colorLoseRounds && colorLoseRounds % 4 === 0) return (Math.floor(Math.random() * ( Math.floor(data.length / 2)) ) + 1 ) / 2
    }
    if (currentBet==='number') {
      if ( (numberLoseRounds && numberLoseRounds % 5 === 0) || (numberLoseRounds===2) ) return betNumber
    }
    return randomIndex;
  }

  const handleSpinClick = () => {
    setPrizeNumber(getPrizeNumber());
    setMustSpin(true)
    setShowLottie(false)
    setRounds(rounds+1)
    setEndGame(0)
  }


  const updateBetNumber = (event) => {
    setBetNumber(event.target.value)
    setBetColor('')
  }

  const isBetNumberButtonDisabled = () => {
    const disabled = mustSpin || betNumber === '';
    return disabled
  }

  const updateBetColor = (event) => {
    setBetColor(event.target.value)
    setBetNumber('')
  }

  const isBetColorButtonDisabled = () => {
    const disabled = mustSpin || betColor === '';
    return disabled
  }

  const handleBetColorSpin = () => {
    updateCredit(credit-5)
    setCurrentBet('color')
    handleSpinClick()
  }

  const handleBetNumberSpin = () => {
    updateCredit(credit-15)
    setCurrentBet('number')
    handleSpinClick()
  }

  const finishedSpinning = () => {
    setMustSpin(false)
    if (prizeNumber===0) {
      updateCredit(credit+100)
      setShowLottie(true)
      return
    }
    if (currentBet==='color'){
      if (betColor==='grey') {
        if (prizeNumber%2===0) {
          updateCredit(credit+20)
          setShowLottie(true)
          return
        } else {
          setColorLoseRounds(colorLoseRounds+1)
        }
      } else if (betColor==='red') {
        if (prizeNumber%2!==0) {
          updateCredit(credit+20)
          setShowLottie(true)
          return
        } else {
          setColorLoseRounds(colorLoseRounds+1)
        }
      }
    }
    if (currentBet==='number'){
      if (betNumber==prizeNumber) {
        updateCredit(credit+80)
        setShowLottie(true)
        return
      } else {
        setNumberLoseRounds(numberLoseRounds+1)
      }
    }
  }

  const handleEndGame = () => {
    if (!endGame){
      setEndGame(1)
      return;
    }
    callNextScreen();
  }


  return (
    <>
      {/* <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}

        onStopSpinning={(e) => {
          setMustSpin(false)
        }}
      /> */}


      {/* <Wheel
      mustStartSpinning={mustSpin}
      prizeNumber={3}
      data={data}
      backgroundColors={['#3e3e3e', '#df3428']}
      textColors={['#ffffff']}
    /> */}

      <h1 style={{ color: 'white', fontSize: "55px" }}>Roulette</h1>

      <div style={{ marginTop: '-22px' }}>
        <span style={{ color: 'white', fontSize: '17px' }}>
          Your Credit: <strong>{credit}$</strong>
        </span>
      </div>

      <div style={{ position: 'fixed', top: '25%', left: '29%' }}>

        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          backgroundColors={backgroundColors}
          textColors={textColors}
          fontSize={fontSize}
          outerBorderColor={outerBorderColor}
          outerBorderWidth={outerBorderWidth}
          innerRadius={innerRadius}
          innerBorderColor={innerBorderColor}
          innerBorderWidth={innerBorderWidth}
          radiusLineColor={radiusLineColor}
          radiusLineWidth={radiusLineWidth}
          perpendicularText
          textDistance={textDistance}
          onStopSpinning={finishedSpinning}
        />

      </div>

      <Card style={{ width: '250px', position: 'fixed', top: '35%', left: '2%' }}>

        <CardContent>

          <Typography variant="h5" gutterBottom>
            {`Number Bet`}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {`Costs 15$`}
          </Typography>
          <Typography variant="h7" gutterBottom>
            {`Win 80$`}
          </Typography><br />

          <FormControl className={classes.margin} >
            <NativeSelect
              labelId="bet-number-select-label"
              id="bet-number-select"
              value={betNumber}
              onChange={updateBetNumber}
              disabled={mustSpin}
            >
              <option value={''}>{''}</option>
              {
                [...Array(data.length).keys()].map((index) => {
                  if (!index) return
                  return <option value={index} key={index}>{index}</option>
                })
              }
            </NativeSelect>
          </FormControl>

        </CardContent>


        <CardActions style={{ justifyContent: 'center' }}>
          <Button color="primary" onClick={handleBetNumberSpin} disabled={isBetNumberButtonDisabled()} >
            SPIN
                  </Button>
        </CardActions>
      </Card>





      <Card style={{ width: '250px', position: 'fixed', top: '35%', left: '74%' }}>

        <CardContent>

          <Typography variant="h5" gutterBottom>
            {`Color Bet`}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {`Costs 5$`}
          </Typography>
          <Typography variant="h7" gutterBottom>
            {`Win 20$`}
          </Typography><br />

          <FormControl className={classes.margin} >
            <NativeSelect
              labelId="bet-color-select-label"
              id="bet-color-select"
              value={betColor}
              onChange={updateBetColor}
              disabled={mustSpin}
            >
              <option value={''}>{''}</option>
              <option value={'grey'} style={{color: '#3e3e3e'}}>{'Grey'}</option>
              <option value={'red'} style={{color: '#df3428'}}>{'Red'}</option>
            </NativeSelect>
          </FormControl>

        </CardContent>


        <CardActions style={{ justifyContent: 'center' }}>
          <Button color="primary" onClick={handleBetColorSpin} disabled={isBetColorButtonDisabled()} >
            SPIN
                  </Button>
        </CardActions>
      </Card>

      <Button variant="contained" onClick={handleEndGame} 
            style={{ position: 'fixed', top: '89%', left: '45%' }}
            >End Game</Button>

      {
        showLottie ? (
          <WinLottie />
        ) : (null)
      }
    </>
  )
}


export default Roulette