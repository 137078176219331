// import './css/style.css';
import { Card, CardStyles } from 'react-casino';
import { Hand, HandStyles, Games } from 'react-casino';
import Lottie from 'react-lottie';
import animationData from './4946-coin-firework.json';

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function WinLottie() {
  return (
    <div style={{ top: '35%', left: '26%', position: 'fixed', pointerEvents: 'none' }}>

      <Lottie
        options={defaultOptions}
        height={500}
        width={500}
        isStopped={false}
      />

    </div>
  );
}

export default WinLottie;
