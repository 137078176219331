
export const generateCardsPack = () => {
    const suits = ['C','D','H','S']
    const faces = ['A', '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'J', 'Q', 'K']
    const pack = []
    suits.forEach((suit)=> {
        faces.forEach((face)=>{
            pack.push(`${face}${suit}`)  //Normal Structure
            // pack.push({suit, face}) //'react-casino' structure
        })
    })
    return pack
}

export const shuffleArray = (arr) => {
    if (arr.length < 2) return arr;
    const array = Array.from(arr);
    let j;
    let x;
    for (let i = array.length - 1; i > 0; i -= 1) {
      j = Math.floor(Math.random() * (i + 1));
      x = array[i];
      array[i] = array[j];
      array[j] = x;
    }
    return array;
  };

export const getShuffledPack = () => {
    return shuffleArray(generateCardsPack())
}

export const getBlackjackShuffledPack = () => {
    const numOfPacks = 6
    let pack = []
    const res = [...Array(numOfPacks)].forEach(() => {
        pack = pack.concat(generateCardsPack());
      });
      
    return shuffleArray(pack)
}