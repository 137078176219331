// import React, {useState} from 'react';
import { useState } from 'react';
import './css/style.css';
import Welcome from '../components/Welcome/Welcome'
import Rules from '../components/RulesTemplate/Rules'
import Intro from '../components/IntroTemplate/Intro'
import {rules, intros} from './const'
import SlotsMachine from '../components/SlotsMachine/SlotsMachine'
import Package from '../components/PackageTemplate/Package'
import Roulette from '../components/Roulette/Roulette'
import Blackjack from '../components/Blackjack/Blackjack'
import ChooseGame from '../components/ChooseGame/ChooseGame'
import ScratchCards from '../components/ScratchCards/ScratchCards'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobileOnly
} from "react-device-detect";

const screens = [
  'welcome',
  'casino-rules',
  'slot-machine-intro',
  'slot-machine-rules',
  'slot-machine-game',
  'auction-intro1',
  'auction-rules',
  'auction-first',
  'roulette-intro',
  'roulette-rules',
  'roulette-game',
  'auction-intro2',
  'auction-second',
  'blackjack-intro',
  'blackjack-rules',
  'blackjack-game',
  'auction-intro3',
  'auction-third',
  'choosegame',
  'auction-intro4',
  'auction-fourth',
  'auction-intro5',
  'auction-fifth',
  'scratch-cards-intro',
  'scratch-cards',
  'goodbye',
];

const getStartingCredit = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const credit = urlParams.get('credit')
  if (credit) {
    return credit
  }
  return 100
}

function Home() {

  const [credit, setCredit] = useState(getStartingCredit());
  // const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const [currentScreen, setCurrentScreen] = useState('welcome');
  const [forcedScreenFrom, setForcedScreenFrom] = useState('');
  const [presentsLeft, setPresentsLeft] = useState(10);

  const callNextScreen = () => {
    if (forcedScreenFrom) {
      setCurrentScreen(forcedScreenFrom)
      return
    }
    const nextIndex = screens.indexOf(currentScreen)+1;
    setCurrentScreen(screens[nextIndex])
  }

  const forceScreen = (screenFrom, screenTo) => {
    // setForcedScreenFrom('choosegame')
    if (!screenTo) {
      setForcedScreenFrom('')
      return
    }
    setForcedScreenFrom(screenFrom)
    setCurrentScreen(screenTo)
  }

  const updateCredit = (newAmount) => {
    setCredit(newAmount)
  }

  const substractPresent = () => {
    setPresentsLeft(presentsLeft-1)
  }

  const getRemaningPresentsForLastAuction = () => {
    const budget = Math.floor(credit / 2)
    const cost = Math.floor(budget / presentsLeft)
    const packages = {}
    const arr = [...Array(presentsLeft)]
    arr.forEach((present, index) => {
      if (Object.keys(packages).length===3) return;
      packages[index] = cost
    });
    return packages
  }

  const calculatePackagePrice = (percent) => Math.floor(credit * percent / 100);
  const trimNumbersFromString = (str) => str.replace(/[0-9]/g, '')  //for auction1, auction2...
  
  const getScreenComponent = () => {
    console.log(presentsLeft);
    switch (trimNumbersFromString(currentScreen)) {
      case 'welcome':
        return <Welcome callNextScreen={callNextScreen} />
      case 'slot-machine-game':
        return <SlotsMachine callNextScreen={callNextScreen} credit={credit} updateCredit={updateCredit} />
      case 'casino-rules':
        return <Rules callNextScreen={callNextScreen} ruleData={rules[currentScreen]} />
      case 'slot-machine-intro':
        return <Intro callNextScreen={callNextScreen} introData={intros[currentScreen]} />
      case 'slot-machine-rules':
        return <Rules callNextScreen={callNextScreen} ruleData={rules[currentScreen]} />
      case 'goodbye':
        return <Rules ruleData={rules[currentScreen]} />
      case 'auction-first':
        return <Package callNextScreen={callNextScreen} packages={{'A':calculatePackagePrice(25), 'B':calculatePackagePrice(35), 'C':calculatePackagePrice(15)}} credit={credit} updateCredit={updateCredit} substractPresent={substractPresent} />
      case 'auction-second':
        return <Package callNextScreen={callNextScreen} packages={{'A':calculatePackagePrice(80), 'B':calculatePackagePrice(30), 'C':calculatePackagePrice(30)}} credit={credit} updateCredit={updateCredit} substractPresent={substractPresent} />
      case 'auction-third':
        return <Package callNextScreen={callNextScreen} packages={{'A':calculatePackagePrice(60), 'B':calculatePackagePrice(30), 'C':calculatePackagePrice(20)}} credit={credit} updateCredit={updateCredit} substractPresent={substractPresent} />
      case 'auction-fourth':
        return <Package callNextScreen={callNextScreen} packages={getRemaningPresentsForLastAuction()} credit={credit} updateCredit={updateCredit} substractPresent={substractPresent} />
      case 'auction-fifth':
        return <Package callNextScreen={callNextScreen} packages={getRemaningPresentsForLastAuction()} credit={credit} updateCredit={updateCredit} substractPresent={substractPresent} />
      case 'auction-intro':
        return <Intro callNextScreen={callNextScreen} introData={intros[trimNumbersFromString(currentScreen)]} />
      case 'auction-rules':
        return <Rules callNextScreen={callNextScreen} ruleData={rules[currentScreen]} />
      case 'roulette-game':
        return <Roulette callNextScreen={callNextScreen} credit={credit} updateCredit={updateCredit} />
      case 'roulette-intro':
        return <Intro callNextScreen={callNextScreen} introData={intros[currentScreen]} />
      case 'roulette-rules':
        return <Rules callNextScreen={callNextScreen} ruleData={rules[currentScreen]} />
      case 'blackjack-intro':
        return <Intro callNextScreen={callNextScreen} introData={intros[currentScreen]} />
      case 'blackjack-rules':
        return <Rules callNextScreen={callNextScreen} ruleData={rules[currentScreen]} />
      case 'blackjack-game':
        return <Blackjack callNextScreen={callNextScreen} credit={credit} updateCredit={updateCredit} />
      case 'choosegame':
        return <ChooseGame callNextScreen={callNextScreen} forceScreen={forceScreen} credit={credit} />
      case 'scratch-cards-intro':
        return <Intro callNextScreen={callNextScreen} introData={intros[currentScreen]} />
      case 'scratch-cards':
        return <ScratchCards callNextScreen={callNextScreen} credit={credit} updateCredit={updateCredit} />
        default:
        return null
    }
  }

  return (
    <>
      <div>
        <div class="header-top">
          <section class="slider_section">
            {/* <div class="banner_main"> */}


            {/* Container */}
            {/* <Homepage /> */}
            {/* <SlotsMachine /> */}

            {/* For Testing!: */}
            {/* <Package /> */}
            {
              isMobileOnly ? (
                <span style={{color: 'white', position: 'fixed', top: '40%', left: '26%'}}>
                  Welcome Sapir! <br />
                  For better experience, <br />
                  Please open me from iPad <br />
                  See you there :)
                </span>
              ) : (
                getScreenComponent()
              )
            }


            {/* <div class="imagepng"></div> */}
            {/* </div> */}
          </section>
        </div>
      </div>
      <div>
        <div class="footer top_layer ">

        </div>
      </div>
    </>
  );
}

export default Home;
