// import './css/style.css';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {stringToTitleCase} from '../../utils'
import animationData from '../../lottie/57856-gift-animation.json';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

// const useStyles = makeStyles({
//   root: {
//     maxWidth: 250,
//   },
//   media: {
//     height: 140,
//   },
// });
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  media: {
    height: 140,
  },
}))

const games = ['slot-machine-game', 'roulette-game', 'blackjack-game']

function ChooseGame({ callNextScreen, credit, forceScreen }) {
  const classes = useStyles();

  const chooseGame = (game) => {
    forceScreen('choosegame', game)
  }

  const handleContinue = () => {
    forceScreen('','')
    callNextScreen()
  }

  const getGameTitle = (game) => {
    const title = game.substring(0, game.length - 5);
    return stringToTitleCase(title)
  }

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <span style={{ color: 'white', fontSize: '17px' }}>
          Your Credit: <strong>{credit}$</strong>
            </span>
      </div>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {games.map(game => (
            <Grid item xs={12} sm={6} md={3} key={games.indexOf(game)}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {getGameTitle(game)}
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button size="small" color="primary" onClick={() => chooseGame(game)} >
                    Play
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{top: '80%',  left: '45%', position: 'fixed'}}>
      <Button variant="contained" onClick={handleContinue} >Continue</Button>
      </div>
    </>
  )


}

export default ChooseGame;
