import {BrowserRouter as Router, Route} from 'react-router-dom'
import Test from './components/Test/Test'
import Home from './template/Home'

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Main Route */}
        <Route exact path={'/'} component={Home} />
        <Route exact path={'/test'} component={Test} />
      </div>
    </Router>
  );
}

export default App;
