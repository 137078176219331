// import './css/style.css';
import { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'


import Lottie from 'react-lottie';
import animationData from '../../lottie/57856-gift-animation.json';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const buyStatus = (packagesObj) => {
  const packagesStatus = {}
  Object.keys(packagesObj).forEach(key => {
    packagesStatus[key] = 0
  });
  return packagesStatus
}


// const useStyles = makeStyles({
//   root: {
//     maxWidth: 250,
//   },
//   media: {
//     height: 140,
//   },
// });
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  media: {
    height: 140,
  },
}))

function Package({ callNextScreen, packages, credit, updateCredit, substractPresent }) {
  const classes = useStyles();

  const clonedPackages = useMemo(() => packages, []);


  //     return (
  //       <Container>
  //       <Row className="justify-content-md-center">
  //         <Col xs lg="2">

  //           {/* 1 of 3 */}
  //         <Card className={classes.root}>
  //           <CardActionArea>
  //             <CardMedia
  //               className={classes.media}
  //               image="/static/images/cards/contemplative-reptile.jpg"
  //               title="Contemplative Reptile"
  //             />
  //             <CardContent>
  //               <Typography gutterBottom variant="h5" component="h2">
  //                 Lizard
  //               </Typography>
  //               <Typography variant="body2" color="textSecondary" component="p">
  //                 Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
  //                 across all continents except Antarctica
  //               </Typography>
  //             </CardContent>
  //           </CardActionArea>
  //           <CardActions>
  //             <Button size="small" color="primary">
  //               Share
  //             </Button>
  //             <Button size="small" color="primary">
  //               Learn More
  //             </Button>
  //           </CardActions>
  //         </Card>

  //         </Col>
  //         {/* <Col>2 of 3</Col>
  //         <Col>3 of 3</Col> */}


  // <Col xs lg="2">

  // {/* 1 of 3 */}
  // <Card className={classes.root}>
  // <CardActionArea>
  //   <CardMedia
  //     className={classes.media}
  //     image="/static/images/cards/contemplative-reptile.jpg"
  //     title="Contemplative Reptile"
  //   />
  //   <CardContent>
  //     <Typography gutterBottom variant="h5" component="h2">
  //       Lizard
  //     </Typography>
  //     <Typography variant="body2" color="textSecondary" component="p">
  //       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
  //       across all continents except Antarctica
  //     </Typography>
  //   </CardContent>
  // </CardActionArea>
  // <CardActions>
  //   <Button size="small" color="primary">
  //     Share
  //   </Button>
  //   <Button size="small" color="primary">
  //     Learn More
  //   </Button>
  // </CardActions>
  // </Card>

  // </Col>



  //       </Row>
  //       </Container>
  //     );


  const [packagesStatus, setPackagesStatus] = useState(buyStatus(clonedPackages))

  const data = [
    { quarter: 1, earnings: 13000 },
    { quarter: 2, earnings: 16500 },
    // { quarter: 3, earnings: 14250 },
    // { quarter: 4, earnings: 19000 }
  ]


  const buyPackage = (key) => {
    setPackagesStatus({ ...packagesStatus, [key]: 1 })
    updateCredit(credit - clonedPackages[key])
    substractPresent()
  }


  const isBuyDisabled = (key) => {
    if (packagesStatus[key] || clonedPackages[key] > credit) return true;
    return false
  }

  const getButtonTitle = (key) => {
    if (packagesStatus[key]) return 'Already Bought';
    if (clonedPackages[key] > credit) return 'Not Enough Money :(';
    return 'Buy';
  }

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <span style={{ color: 'white', fontSize: '17px' }}>
          Your Credit: <strong>{credit}$</strong>
            </span>
      </div>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {Object.keys(clonedPackages).map(key => (
            <Grid item xs={12} sm={6} md={3} key={Object.keys(clonedPackages).indexOf(key)}>
              <Card>
                {/* <CardMedia
                    className={classes.media}
                    image="/assets/intros/slot-machine.png"
                    title="Contemplative Reptile"
                  /> */}
                {/* <CardHeader
                          title={`Package: ${key}`}
                          // subheader={`earnings : ${packages[key]}`}
                      /> */}


                <CardContent>
                  <Lottie
                    options={defaultOptions}
                    height={150}
                    width={150}
                    isStopped={!packagesStatus[key]}
                  />
                  <Typography variant="h5" gutterBottom>
                    {`Package: ${key}`}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {`Costs: ${clonedPackages[key]}$`}
                  </Typography>
                  <Typography variant="h7" gutterBottom style={isBuyDisabled(key) ? { color: 'white' } : null}>
                    {`You'll have: ${credit - clonedPackages[key]}$`}
                  </Typography>
                </CardContent>


                <CardActions style={{ justifyContent: 'center' }}>
                  <Button size="small" color="primary" onClick={() => buyPackage(key)} disabled={isBuyDisabled(key)} >
                    {getButtonTitle(key)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{top: '80%',  left: '45%', position: 'fixed'}}>
      <Button variant="contained" onClick={callNextScreen} 
      >Continue</Button>
      </div>
    </>
  )


}

export default Package;
