// import './css/style.css';

import Lottie from 'react-lottie';
import animationData from '../../lottie/18304-kiss-of-the-heart.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function Rules({callNextScreen, ruleData}) {
  const { title, bullets } = ruleData;
  return (
    <div class="container">

    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
        <div class="text-bg">
          <h1>{title}</h1>
          {/* <h1>Casino Rules</h1> */}
          <ul style={{color: 'white', fontSize: '17px'}}>
            {
              bullets.map(bullet => {
                return <li>{bullet}</li>
              })
            }
            {/* <li>Have fun</li>
            <li>Have fun more</li> */}
          </ul>
          {/* <span>Let's get the party started!!! <br />
          Sapir,  you have 100$ chips,   let's start wasting them!
          </span> */}
          {!callNextScreen ? (
            <div>
              <Lottie 
                options={defaultOptions}
                height={400}
                width={400}
              />
            </div>
          ) : (
            <a onClick={callNextScreen} style={{marginTop:'50px'}}>Continue</a>
          )}
          {/* <a href="#">Book Online</a> */}
        </div>
      </div>
      {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12"> */}
      {/* <div class="text-img"> */}
      {/* </div>
          </div> */}
    </div>

  </div>
  );
}

export default Rules;
