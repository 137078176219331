// import './css/style.css';
import Lottie from 'react-lottie';

function Intro({callNextScreen, introData}) {
  const { title, image, lottie, style } = introData
  console.log(introData);

  let animationData, defaultOptions = null;
  if (lottie) {
    animationData = require(`../../lottie/${lottie}.json`);

    defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

    return (
      <div class="container">
  
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
          <div class="text-bg">
            <h1>{title}</h1>
            {/* <h1>Casino Rules</h1> */}
            <div style={style}>
              {
                lottie ? (
                  <Lottie 
                    options={defaultOptions}
                    // height={450}
                    width={450}
                    // isStopped={false}
                    
                  />
                ) : (
                  <img src={`/assets/intros/${image}`} style={{width: '450px'}} />
                )
              }
            </div>
            <a onClick={callNextScreen} style={{position: 'fixed', left: '41%', top: '85%'}}>Continue</a>
            {/* <a onClick={callNextScreen} style={{position: 'fixed', left: '40%', top: '400%'}}>Continue</a> */}
          </div>
        </div>
      </div>
  
    </div>
    );
  }
  
  export default Intro;
  