// import './css/style.css';
import { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'


import Lottie from 'react-lottie';
import animationData from '../../lottie/43714-scratch-card.json';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const buyStatus = (packagesObj) => {
  const packagesStatus = {}
  Object.keys(packagesObj).forEach(key => {
    packagesStatus[key] = 0
  });
  return packagesStatus
}


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  media: {
    height: 140,
  },
}))



function ScratchCards({ callNextScreen, credit, updateCredit }) {
  const classes = useStyles();

  const scratchCards = useMemo(() => {
    const deduct = credit > 12 ? 12 : 3
    const cost = Math.floor( ( credit - deduct ) / 3 )
    return {"A":cost, "B":cost, "C":cost}
  }, []);


  const [packagesStatus, setPackagesStatus] = useState(buyStatus(scratchCards))

  const buyPackage = (key) => {
    setPackagesStatus({ ...packagesStatus, [key]: 1 })
    updateCredit(credit - scratchCards[key])
  }


  const isBuyDisabled = (key) => {
    if (packagesStatus[key]) return true;
    return false
  }

  const getButtonTitle = (key) => {
    if (packagesStatus[key]) return 'Already Bought';
    return 'Buy';
  }

  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <span style={{ color: 'white', fontSize: '17px' }}>
          Your Credit: <strong>{credit}$</strong>
            </span>
      </div>
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {Object.keys(scratchCards).map(key => (
            <Grid item xs={12} sm={6} md={3} key={Object.keys(scratchCards).indexOf(key)}>
              <Card>
                {/* <CardMedia
                    className={classes.media}
                    image="/assets/intros/slot-machine.png"
                    title="Contemplative Reptile"
                  /> */}
                {/* <CardHeader
                          title={`Package: ${key}`}
                          // subheader={`earnings : ${packages[key]}`}
                      /> */}


                <CardContent>
                  <Lottie
                    options={defaultOptions}
                    height={150}
                    width={150}
                    isStopped={!packagesStatus[key]}
                  />
                  <Typography variant="h5" gutterBottom>
                    {`Scratch-Card`}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {`Costs: ${scratchCards[key]}$`}
                  </Typography>
                  <Typography variant="h7" gutterBottom style={isBuyDisabled(key) ? { color: 'white' } : null}>
                    {`You'll have: ${credit - scratchCards[key]}$`}
                  </Typography>
                </CardContent>


                <CardActions style={{ justifyContent: 'center' }}>
                  <Button size="small" color="primary" onClick={() => buyPackage(key)} disabled={isBuyDisabled(key)} >
                    {getButtonTitle(key)}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{top: '80%',  left: '45%', position: 'fixed'}}>
      <Button variant="contained" onClick={callNextScreen} 
      >Continue</Button>
      </div>
    </>
  )


}

export default ScratchCards;
