import React from 'react'
import './style.css'
import Button from '@material-ui/core/Button';
import WinLottie from '../../lottie/WinLottie'

const rootElement = document.getElementById('root')
  
function RepeatButton(props) {
  return (
    <button 
      aria-label='Play again.' 
      id='repeatButton' 
      onClick={props.onClick}>
    </button>
  );
}

function WinningSound() {
  return (
  <audio autoplay="autoplay" className="player" preload="false">
    <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" />
  </audio>  
  );
}

const slotChoises = [
  -9400, //Banana
  -4136, //Pinapple
  -13912, //Pizza
  -6392, //Avocado
  -3384, //Cherry
  -4512, //Beer
  -5640, //Broccoli
  -3196, //Corn
  -5264, //Burger
];

const getRandomSlotChoise = () => {
  const randomIndex = Math.floor(Math.random()*(9));
  return slotChoises[randomIndex];
}

class SlotsMachine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      winner: null,
      rounds: 0,
      end: 0,
    }
    this.finishHandler = this.finishHandler.bind(this)
    this.handleClick = this.handleClick.bind(this);
  }  

  handleClick() { 
    this.setState({...this.state, rounds: this.state.rounds+1, winner: null, end: 0 });
    this.emptyArray();
    this._child1.forceUpdateHandler();
    this._child2.forceUpdateHandler();
    this._child3.forceUpdateHandler();
    this.props.updateCredit(this.props.credit-5);
    // console.log(this.state.rounds);
  }

  static loser = [
    'Not quite', 
    'Stop gambling', 
    'Hey, you lost!', 
    'Ouch! I felt that',      
    'Don\'t beat yourself up',
    'There goes the college fund',
    'I have a cat. You have a loss',
    'You\'re awesome at losing',
    'Coding is hard',
    'Don\'t hate the coder'
  ];

  static matches = [];

  finishHandler(value, nonBiasedWin=false) {
    console.log(value);
    SlotsMachine.matches.push(value);  

    if (SlotsMachine.matches.length === 3) {
      const { winner } = this.state;
      const first = SlotsMachine.matches[0];
      let results = SlotsMachine.matches.every(match => match === first)
      this.setState({...this.state,  winner: results });
      if (results){
        this.props.updateCredit(this.props.credit+30);
        if (nonBiasedWin) {
          this.setState({ ...this.state, rounds: 0 });
        }
      }
    }
  }

  emptyArray() {
    SlotsMachine.matches = [];
  }

  render() {
    const { winner } = this.state;
    const getLoser = () => {       
      return SlotsMachine.loser[Math.floor(Math.random()*SlotsMachine.loser.length)]
    }
    let repeatButton = null;
    let winningSound = null;

    // if (winner !== null || !this.state.rounds) {
    //   repeatButton = <RepeatButton onClick={this.handleClick} />
    // }
    
    if (winner) {
      // winningSound = <WinningSound />
    }

    const isBiased = () => {
      // return true //test
      if (this.state.rounds !== 0 && ( this.state.rounds%7===0 || this.state.rounds%11===0) ) {
        return true
      }
      return false
    }

    const biasedWin = () => {
      if (!isBiased()) return 0;
      return getRandomSlotChoise();
    }

    const isButtonDisabled = () => {
      return !(winner !== null || !this.state.rounds)
    }

    const bwin = biasedWin()

    const handleEndGame = () => {
      if (!this.state.end){
        this.setState({...this.state, end: 1})
        return;
      }
      this.props.callNextScreen();
    }

    return (
      <div>
        {winningSound}
        {/* <h1 style={{ color: 'white'}}>
          <span>{winner === null ? 'Waiting…' : winner ? '🤑 Pure skill! 🤑' : getLoser()}</span>
        </h1> */}
        <h1 style={{color: 'white', fontSize: "55px"}}>Slots Machine</h1>

        <div className={`spinner-container`}>
          <Spinner onFinish={this.finishHandler} ref={(child) => { this._child1 = child; }} timer="1000" biasedWin={bwin} rounds={this.state.rounds} />
          <Spinner onFinish={this.finishHandler} ref={(child) => { this._child2 = child; }} timer="1400" biasedWin={bwin} rounds={this.state.rounds} />
          <Spinner onFinish={this.finishHandler} ref={(child) => { this._child3 = child; }} timer="2200" biasedWin={bwin} rounds={this.state.rounds} />
          <div className="gradient-fade"></div>
        </div>
        <div style={{top: '55%', left: '43%', position: 'fixed'}}>
          <div style={{marginBottom: '20px'}}>
            <span style={{color: 'white', fontSize: '17px'}}>
              Your Credit: <strong>{this.props.credit}$</strong>
            </span>
          </div>
          <div  style={{marginBottom: '100px'}}>
            <Button variant="contained" onClick={this.handleClick} disabled={isButtonDisabled()} style={isButtonDisabled() ? {backgroundColor: 'grey', color: 'black'} : null }>SPIN</Button>
          </div>
          <div>
            <Button variant="contained" onClick={handleEndGame} 
            // style={{paddingRight: '40px', paddingLeft: '40px', borderRadius: '10px'}}
            >End Game</Button>
          </div>
        </div>
        {/* {repeatButton} */}
        {
            winner ? (
              <WinLottie />
            ) : (null)
        }
      </div>
    );
  }
}  
  
class Spinner extends React.Component {  
  constructor(props){
    super(props);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
  };

  forceUpdateHandler(){
    this.reset();
  };

  reset() {
    if (this.timer) { 
      clearInterval(this.timer); 
    }  

    this.start = this.setStartPosition();

    this.setState({
      position: this.start,
      timeRemaining: this.props.timer        
    });

    this.timer = setInterval(() => {
      this.tick()
    }, 100);      
  }

  state = {
    position: 0,
    lastPosition: null
  }
  static iconHeight = 188;
  multiplier = Math.floor(Math.random()*(4-1)+1);

  start = this.setStartPosition();
  speed = Spinner.iconHeight * this.multiplier;    

  setStartPosition() {
    return ((Math.floor((Math.random()*9))) * Spinner.iconHeight)*-1;
  }

  moveBackground() {
    this.setState({ 
      position: this.state.position - this.speed,
      timeRemaining: this.state.timeRemaining - 100
    })
  }

  getSymbolFromPosition() {
    let { position } = this.state;
    const totalSymbols = 9;
    const maxPosition = (Spinner.iconHeight * (totalSymbols-1)*-1);
    let moved = (this.props.timer/100) * this.multiplier
    let startPosition = this.start;
    let currentPosition = startPosition;    

    for (let i = 0; i < moved; i++) {              
      currentPosition -= Spinner.iconHeight;

      if (currentPosition < maxPosition) {
        currentPosition = 0;
      }      
    }

    console.log('stopped position: ' + position);
    this.props.onFinish(currentPosition, true);
  }

  tick() {      
    if (this.state.timeRemaining <= 0) {
      clearInterval(this.timer);       
      // const biased = false;
      
      if (this.props.biasedWin) {
        // const randomWin = this.getRandomBiasedWin();
        this.setState({ 
          position: this.props.biasedWin
        })
        this.props.onFinish(this.props.biasedWin);
      } else {
        this.getSymbolFromPosition();    
      }

    } else {
      this.moveBackground();
    }      
  }

  componentDidMount() {
    if (!this.props.rounds) {
      this.setState({
        ...this.state,
        position: getRandomSlotChoise(),
      })
      return
    }
    

    clearInterval(this.timer);

    this.setState({
      position: this.start,
      timeRemaining: this.props.timer
    });

    this.timer = setInterval(() => {
      this.tick()
    }, 100);
  }

  render() {
    let { position, current } = this.state;   

    return (            
      <div 
        style={{backgroundPosition: '0px ' + position + 'px'}}
        className={`icons`}          
      />
    )
  }
}


export default SlotsMachine;
