export const rules = {
    'casino-rules': {
        title: 'Casino Rules',
        bullets: [
            '.יש ברשותך 100$ למשחקי הקזינו',
            '.יהיו לך מספר משחקים בהם תוכלי לנסות להרוויח',
            '.בתחילת כל משחק,  יוצגו החוקים של המשחק',
            '(: בתום המשחק,  אם יהיה ברשותך מספיק כסף,  תוכלי לבחור לקנות לעצמך מתנה',
            '!כיאה לכל קזינו,  אנו מציעים קוקטיילים או בירה (ללא אלכוהול),  וטוסטים שינקן - על חשבון הבית ככל יכולתך',
            ',צוות הקזינו כאן לרשותך לכל שאלה או בקשה',
            '!!תתחילי לבזבז',
        ],
    },
    'slot-machine-rules': {
        title: 'Slot-Machine Rules',
        bullets: [
            '.עלות כל משחק היא 5$',
            '.כל ניצחון (שורה של אותו סמל) מעניק רווח של 30$',
            '!צאי לדרך',
        ]
    },
    'goodbye': {
        title: "Baum's Casino Closed",
        bullets: [
            ',תודה שהשתתפת והתארחת אצלינו בקזינו',
            ',מקווים שנהנית והרחוות',
            '(: מחכים לראותך בעתיד, יש שמועה שיש קזינו מטורף באמסטרדם',
            '!!מאוהבבבבבב',
        ]
    },
    'auction-rules': {
        title: 'Treasure-Hunt Rules',
        bullets: [
            ',יוצגו לך מספר אריזות מתנה',
            ',ללא אפשרות לראות את התכולה',
            ',כל מתנה עולה סכום מסוים של כסף',
            '.את יכולה לבחור כל מתנה/ות כל עוד היתרה מספיקה',
            '.היתרה תעבור איתך הלאה למשחקים הבאים',
            '!!קדימה לבחור מתנה',
        ],
    },
    'roulette-rules': {
        title: 'Roulette Rules',
        bullets: [
            'תוכלי להמר על מספר או צבע',
            'הימור על צבע יעלה 5$, הזכייה תעניק 20$',
            'הימור על מספר יעלה 15$, הזכייה תעניק 80$',
            'במידה ויוצא 0 (ירוק), הזכייה תעניק 100$',
            '!!ספין בייבי ספין',
        ],
    },
    'blackjack-rules': {
        title: 'Black-Jack Rules',
        bullets: [
            'מטרתך להשיג ניקוד של 21 או הכי קרוב מתחת ל21',
            'הניקוד הוא לפי מספר הקלף,  תמונה שווה 10, אס שווה 11',
            'בתחילת המשחק יחולקו לך ולדילר 2 קלפים חשופים',
            'במשך שלושה סיבובים,  באפשרותך למשוך קלף מהקופה (היט), או להמתין תור (סטנד)',
            'קלפים שנמשכים (מעבר לשניים שחולקו) לא יהיו חשופים',
            'משחק עולה 10$',
            'אם קיבלת 21 בעת חלוקת הקלפים (שני הקלפים הראשונים) - זכייה של 100$',
            'ומידה וניצחת (בדיוק 21 או יותר קרוב ל21 מהדילר), זכייה של 40$',
            'חבילת הקלפים בקופה מכילה 4 חבילות קלפים מעורבבות בכל תחילת משחק',
            '!!יאללה לטרוף את הקלפים'
        ],
    },
};

export const intros = {
    'slot-machine-intro': {
        title: 'Slots-Machine!',
        image: '',
        // image: 'slot-machine.png',
        // lottie: '',
        lottie: '23420-slot-machine',
        style: {position: 'fixed', left: '28%', top: '0'}, //Lottie
        // style: {position: 'fixed', left: '27%'} //Image
    },
    'auction-intro': {
        title: 'Treasure-Hunt!',
        image: '',
        lottie: '44487-presente',
        style: {position: 'fixed', top: '24%', left: '27%'},
    },
    'roulette-intro': {
        title: 'Roulette!',
        image: 'roulette-wheel-icon-by-vexels.png',
        lottie: '',
        style: {position: 'fixed', top: '20%', left: '27%'},
    },
    'blackjack-intro': {
        title: 'Black-Jack!',
        image: '',
        lottie: '47410-ace-of-spade',
        style: {position: 'fixed', top: '30%', left: '28%'},
    },
    'scratch-cards-intro': {
        title: 'Scratch-Cards!',
        image: '',
        lottie: '43714-scratch-card',
        style: {position: 'fixed', top: '5%', left: '30%'},
    },
};