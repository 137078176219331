// import './css/style.css';
import { Card, CardStyles } from 'react-casino';
import { Hand, HandStyles, Games } from 'react-casino';
function Test() {
  return (
    <div>
      {/* <CardStyles />
      <Card suit="S" face="A" /> */}
      {/* <HandStyles /> */}
      <Hand cards={[
        { suit: 'S', face: 'A' },
        { suit: 'S', face: 'K' }
      ]} />
    </div>
  );
}

export default Test;
