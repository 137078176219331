import { getBlackjackShuffledPack } from './utils'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import WinLottie from '../../lottie/WinLottie'
import LoseLottie from '../../lottie/LoseLottie'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
}))

function Blackjack({ callNextScreen, credit, updateCredit }) {
  const classes = useStyles();

  const [showWinLottie, setShowWinLottie] = useState(false);
  const [showLoseLottie, setShowLoseLottie] = useState(false);
  const [playerHand, setPlayerHand] = useState([])
  const [playerScore, setPlayerScore] = useState(0)
  const [dealerHand, setDealerHand] = useState([])
  const [dealerScore, setDealerScore] = useState(0)
  const [playingPack, setPlayingPack] = useState([])
  const [totalRounds, setTotalRounds] = useState(0)
  const [totalHits, setTotalHits] = useState(1)
  const [matchEnded, setMatchEnded] = useState(false)
  const [endGame, setEndGame] = useState(0) //Continue to next screen


  useEffect(() => {
    if (!playerScore) return
    if (playerScore === 21 && totalHits === 1) {
      updateCredit(credit + 100)
      setShowWinLottie(true)
      setMatchEnded(true)
    }
    if (playerScore > 21) {
      setShowLoseLottie(true)
      setMatchEnded(true)
    }
  }, [playerScore]);


  useEffect(() => {
    if (!playerHand.length) return
    const newPlayerScore = calculateScore(playerHand)
    // const newPlayerScore = 21
    setPlayerScore(newPlayerScore)
  }, [playerHand]);

  useEffect(() => {
    if (!dealerHand.length) return
    const newDealerScore = calculateScore(dealerHand)
    setDealerScore(newDealerScore)
  }, [dealerHand]);

  useEffect(() => {
    if (totalHits > 3) {
      if (dealerScore > 21) {
        setShowWinLottie(true)
        updateCredit(credit + 40)
      } else if (playerScore > dealerScore) {
        setShowWinLottie(true)
        updateCredit(credit + 40)
      } else {
        setShowLoseLottie(true)
      }
      setMatchEnded(true)
    }
  }, [totalHits]);


  const getBiased21 = (pack) => {
    const newHand = []
    pack.forEach(card => {
      const value = calculateScore([card])
      if (!newHand.length) {
        if (value === 10 || value === 11) newHand.push(card)
      } else {
        if (value + calculateScore(newHand) === 21) {
          newHand.push(card)
        }
      }
    });
    return newHand;
  }

  const clearTable = () => {
    setMatchEnded(false)
    setShowWinLottie(false)
    setPlayerHand([])
    setDealerHand([])
    setPlayingPack([])
    setPlayerScore(0)
    setDealerScore(0)
    setTotalHits(1)
    setEndGame(0)
  }

  const getNewCardFromPack = () => {
    const clonedPack = [...playingPack]
    let newCard = clonedPack.splice(0, 1)
    setPlayingPack(clonedPack)
    return newCard
  }

  const getDealerAction = () => {
    if (dealerScore < 12) {
      setDealerHand([...dealerHand, ...getNewCardFromPack()])
      return
    }

    const toHit = Math.floor(Math.random() * 2)
    if (toHit) {
      setDealerHand([...dealerHand, ...getNewCardFromPack()])
    }
  }

  const deal = () => {
    let shuffledPack = getBlackjackShuffledPack()
    console.log(shuffledPack);

    let newPlayerHand;
    // Bias
    if (totalRounds % 9 === 0 && totalRounds) {
      newPlayerHand = getBiased21(shuffledPack)
    } else {
      newPlayerHand = shuffledPack.splice(0, 2)
    }

    const newDealerHand = shuffledPack.splice(0, 2)
    setPlayerHand(newPlayerHand)
    setDealerHand(newDealerHand)
    setPlayingPack(shuffledPack)
    updateCredit(credit - 10)
    setTotalRounds(totalRounds + 1)
    setEndGame(0)
  }

  const hit = () => {
    setPlayerHand([...playerHand, ...getNewCardFromPack()])
    getDealerAction()
    setTotalHits(totalHits + 1)
    setEndGame(0)
  }

  const stand = () => {
    getDealerAction()
    setTotalHits(totalHits + 1)
    setEndGame(0)
  }

  const calculateScore = (hand) => {
    let score = 0;
    hand.forEach(card => {
      const face = card[0]
      let value;
      switch (face) {
        case 'T':
          value = 10
          break;
        case 'A':
          value = 11
          break;
        case 'J':
          value = 10
          break;
        case 'Q':
          value = 10
          break;
        case 'K':
          value = 10
          break;
        default:
          value = parseInt(face)
          break;
      }
      score = score + value
    });
    return score
  }


  const handleEndGame = () => {
    if (!endGame) {
      setEndGame(1)
      return;
    }
    callNextScreen();
  }



  return (
    <>
      <div style={{ marginBottom: '30px' }}>
        <span style={{ color: 'white', fontSize: '17px' }}>
          Your Credit: <strong>{credit}$</strong>
        </span>
      </div>
      {
        playerHand.length ? (
          <>
            {
              !matchEnded ? (
                <div>
                  <span style={{ color: 'white', fontSize: '17px' }}>
                    Rounds Left: <strong>{4 - totalHits}</strong>
                  </span>
                </div>
              ) : (null)
            }
            <div style={{ marginBottom: '30px' }}>
              <span style={{ color: 'white', fontSize: '17px' }}>
                Your Score: <strong>{playerScore}</strong><br />
                {
                  matchEnded ? (
                    <>Dealer Score: <strong>{dealerScore}</strong></>
                  ) : (null)
                }
              </span>
            </div>
          </>
        ) : (null)
      }
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {playerHand.map(card => (
            <Grid item xs={12} sm={6} md={2} key={playerHand.indexOf(card)}>
              <Card style={{ height: '160px', width: '106px' }}>
                <img src={`/assets/cards/${card}.png`} />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          {dealerHand.map(card => (
            <Grid item xs={12} sm={6} md={2} key={dealerHand.indexOf(card)}>
              <Card style={{ height: '160px', width: '106px' }}>
                {
                  dealerHand.indexOf(card) > 1 && !matchEnded ? (
                    <img src={`/assets/cards/BACK.png`} style={{ height: '100%' }} />
                  ) : (
                    <img src={`/assets/cards/${card}.png`} />
                  )
                }
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{ top: '80%', left: '41%', position: 'fixed' }}>
        {
          matchEnded ? (
            <Button variant="contained" onClick={clearTable} style={{ position: 'fixed', top: '80%', left: '42.5%' }}>Clear Table</Button>
          ) : (
            <>
              {
                !!playerHand.length ? (null) : (
                  <Button variant="contained" onClick={deal} style={{ top: '80%', left: '45%', position: 'fixed' }} >Deal</Button>
                )
              }
              {
                totalHits > 3 || !playerHand.length ? (null) : (
                  <>
                    <Button variant="contained" onClick={hit} >Hit</Button>
                    <Button variant="contained" onClick={stand} style={{ marginLeft: '30px' }} >Stand</Button>
                  </>
                )
              }
            </>
          )
        }
        <Button variant="contained" onClick={handleEndGame} style={{ position: 'fixed', top: '90%', left: '43.5%' }}>Continue</Button>
      </div>
      {
        showWinLottie ? (
          <WinLottie />
        ) : (null)
      }
      <Snackbar open={showLoseLottie} autoHideDuration={2500} onClose={() => { setShowLoseLottie(false) }} style={{ top: '-2%', left: '38%', position: 'fixed' }}>
        <LoseLottie />
      </Snackbar>
    </>
  );
}

export default Blackjack;
